<template>
    <div id="siteLocation" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->
        <!-- -->
        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="siteLocation"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="site_siteLocationSubTitle"></app-sitetitle>
                        </div>
                        <!-- end:: Content Head -->

                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <div class="alert alert-light alert-elevate" role="alert">
                                <div class="alert-icon"><i class="flaticon-map-location kt-font-brand"></i></div>
                                <div class="alert-text">
                                    <span>{{ $t("site_locationHelpText") }}</span>
                                    <br />
                                    <span>{{ $t("site_locationSearchBoxHelp") }}</span>
                                </div>
                            </div>

                            <!-- begin:: Content -->
                            <div class="row">
                                <div class="col-lg-12">
                                    <!--begin::Portlet-->
                                    <div class="kt-portlet kt-portlet--tab">
                                        <div class="kt-portlet__body padding2">
                                            <app-locationmap mode="siteLocation"></app-locationmap>
                                        </div>
                                        <div class="kt-portlet__foot">
                                            <div class="row align-items-center">
                                                <div class="col-lg-3 m--valign-middle">
                                                    <div>
                                                        {{ $t("common_latitude") }}: <span id="siteLocation_currentLatitude" class="kt-font-brand">{{ currentMapLatitude }}</span>
                                                    </div>
                                                    <div>
                                                        {{ $t("common_longitude") }}: <span id="siteLocation_currentLongitude" class="kt-font-brand">{{ currentMapLongitude }}</span>
                                                    </div>
                                                    <div>
                                                        {{ $t("common_zoomLevel") }}: <span id="siteLocation_currentZoomLevel" v-bind:class="[isMinimumZoomLevel ? 'kt-font-brand' : 'kt-font-danger']">{{ currentMapZoom }}</span>
                                                    </div>
                                                </div>
                                                <!-- currentMapZoom::IF zoom level not yet initialized => Do not display text indicator on zoom level -->
                                                <div v-if="currentMapZoom === null" class="col-lg-6"></div>
                                                <!-- currentMapZoom::ELSE display zoom level text indicator -->
                                                <div v-else class="col-lg-6 m--valign-middle marginZeroAuto alert" v-bind:class="[isMinimumZoomLevel ? 'alert-success' : 'alert-danger']" role="alert">
                                                    <div class="alert-icon">
                                                        <i class="fa" v-bind:class="[isMinimumZoomLevel ? 'fa-check' : 'fa-exclamation-triangle']" style="font-size: 1.7em;"></i>
                                                    </div>
                                                    <div class="alert-text">
                                                        <p v-if="isMinimumZoomLevel" class="mb-0 font500">{{ $t("site_locationIndicatorZoomSuccess") }}</p>
                                                        <p v-else class="mb-0 font500">{{ $t("site_locationIndicatorZoomAlert") }}</p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 kt-align-right">
                                                    <button id="siteLocationSaveButton" type="submit" @click="onClickSaveChanges" :disabled="isLocationHasNotChanged || !isMinimumZoomLevel" class="btn btn-brand kt-margin-r-5">
                                                        <i class="la la-save"></i>
                                                        {{ $t("common_saveChanges") }}
                                                    </button>
                                                    <button id="siteLocationCancelButton" @click="onCancel" type="reset" class="btn btn-secondary">{{ $t("common_cancel") }}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--end::Portlet-->
                                </div>
                            </div>
                            <!-- end:: Content -->
                        </div>
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LocationMap from "../map/locationmap.vue";
import SiteTitle from "./../site/widgets/sitetitle.vue";

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            isLocationHasNotChanged: true,
            isMinimumZoomLevel: false
        };
    },
    created: function() {
        console.log("Component(SiteLocation)::created() - called");
        // Load site
        this.getSiteById(this.siteId);
    },
    mounted: function() {
        console.log("Component(SiteLocation)::mounted() - Init metronic layout");
        // Init Metronic layout
        KTLayout.init();
    },
    destroyed: function() {
        console.log("Component(SiteLocation)::destroyed() - called");
        this.resetSitesState();
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        hasMovedFromInitialInit: function() {
            this.isLocationHasNotChanged = false;
        },

        currentMapZoom: function(zoomLevel) {
            if (zoomLevel < 17) {
                this.isMinimumZoomLevel = false;
            } else {
                this.isMinimumZoomLevel = true;
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["currentMapLatitude", "currentMapLongitude", "currentMapZoom", "currentSite", "hasMovedFromInitialInit"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["getSiteById", "updateSite", "resetSitesState"]),

        // Function called when user click on the "Cancel" button
        onCancel() {
            console.log("Component(SiteLocation)::onCancel() - called");
            this.$router.push({ name: "siteOverview", params: { siteId: this.siteId } });
        },

        onClickSaveChanges() {
            let siteData = {
                ...this.currentSite,
                siteTypeId: this.currentSite.siteType.id,
            };
            delete siteData.siteType;
            // Update the value of location and zoom
            siteData['location'] = {
                type: "Point",
                coordinates: [this.currentMapLongitude, this.currentMapLatitude]
            };
            siteData['zoom'] = this.currentMapZoom < 17 ? 17 : this.currentMapZoom;
            // Update the site in the backend
            this.updateSite(siteData);
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-locationmap": LocationMap,
        "app-sitetitle": SiteTitle
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
.padding2 {
    padding: 2px;
    z-index: 1;
}
.marginZeroAuto {
    margin: 0 auto;
}
.font500 {
    font-weight: 500;
}
</style>
